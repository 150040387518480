"use client"

import { Checkout } from "@slice-so/react"

export default function CheckoutView({
  showBanner = true
}: {
  showBanner?: boolean
}) {
  return (
    <>
      <div className="max-w-screen-sm mx-auto">
        <Checkout
          buttonProps={{
            className: "sl-rounded-full"
          }}
          buttonLabel="Pay onchain"
        />
      </div>
    </>
  )
}
