"use client"

import "@rainbow-me/rainbowkit/styles.css"
import {
  ProductCart,
  formatCartParams,
  generateCart,
  sliceChains
} from "@slice-so/core"
import { SliceProvider } from "@slice-so/react"
import "@slice-so/react/dist/style.css"
import { useSearchParams } from "next/navigation"
import { alchemyProvider } from "wagmi/providers/alchemy"
import { infuraProvider } from "wagmi/providers/infura"
import { publicProvider } from "wagmi/providers/public"
import { jsonRpcProvider } from "wagmi/providers/jsonRpc"
import { configureChains, useAccount, useContractReads } from "wagmi"
import { theme, useModalContext } from "./ClientLayout"
import { abi } from "@lib/ProductsModule"
import {
  Context,
  Suspense,
  createContext,
  useContext,
  useEffect,
  useState
} from "react"

// exemple url: http://localhost:3000?cart=1-3&cart=3-2

const infuraId = process.env.NEXT_PUBLIC_INFURA_ID || ""
const alchemyId = process.env.NEXT_PUBLIC_ALCHEMY_ID || ""
const chainId = process.env.NEXT_PUBLIC_CHAIN_ID || "8453"
const isRelayer = false // !!process.env.NEXT_PUBLIC_RELAYER

const { chains } = configureChains(sliceChains(chainId), [
  alchemyProvider({ apiKey: alchemyId }),
  jsonRpcProvider({
    rpc: () => ({
      http: `https://restless-side-vineyard.base-mainnet.quiknode.pro/43312ff3bb792b15d15dff7cda4aefe003329b54/`
    })
  }),
  jsonRpcProvider({
    rpc: () => ({
      http: `https://nd-418-081-331.p2pify.com/e2a12fe09f051376939ba43a60038cad`
    })
  }),
  // infuraProvider({ apiKey: infuraId }),
  publicProvider()
])

export const productsModuleContract = {
  address: "0xb9d5B99d5D0fA04dD7eb2b0CD7753317C2ea1a84",
  abi,
  chainId: 8453,
  functionName: "availableUnits"
}

const Layout = ({
  children,
  allProducts
}: {
  children: React.ReactNode
  allProducts: ProductCart[]
}) => {
  const isDelivery = true
  const isAllProducts = true
  const { setIsConnectModalOpen } = useModalContext()

  return (
    <SliceProvider
      storeName="Bright Moments Venice"
      chains={chains}
      initCart={allProducts}
      notificationReceiver={process.env.NEXT_PUBLIC_RECEIVER}
      theme={theme}
      socialShareText={
        "I just bought merch onchain at Bright Moments Venice. No gas and one click buy with @coinbasewallet on @slice"
      }
      isAllProducts={isAllProducts}
      isDelivery={isDelivery}
      isRelayer={isRelayer}
      storeUrl={process.env.NEXT_PUBLIC_APP_URL}
      customSuccessMessage={
        <div>
          <p>
            Your order has been placed. Order will be shipped within 7-10
            business days.
          </p>
          <p>
            For any questions related to your order, message{" "}
            <a
              href={"https://twitter.com/toady_hawk"}
              className="font-medium underline"
            >
              Toady Hawk
            </a>
            . Make sure to include your order number in the message.
          </p>
        </div>
      }
      openCustomConnectModal={() => {
        setIsConnectModalOpen(true)
      }}
    >
      {children}
    </SliceProvider>
  )
}

export default function DeliveryProvider({
  children,
  allProducts
}: {
  children: React.ReactNode
  allProducts: ProductCart[]
}) {
  // const { data } = useContractReads({
  //   // @ts-ignore
  //   contracts: allProducts.map((product) => ({
  //     ...productsModuleContract,
  //     args: [product.slicerId, product.productId]
  //   }))
  // })
  // const deliveryProducts = allProducts.map((product, index) => ({
  //   ...product,
  //   availableUnits:
  //     Number((data?.[index]?.result as any)?.[0]) || product.availableUnits
  // }))

  return <Layout allProducts={allProducts}>{children}</Layout>
}
