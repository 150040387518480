"use client"

import { ShoppingBag } from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu"
import { HamburgerMenuIcon } from "@radix-ui/react-icons"
import { useAccount } from "wagmi"
import Link from "next/link"
import { useEffect, useState } from "react"

export function DropdownNav() {
  const [isClient, setIsClient] = useState(false)
  const { address } = useAccount()

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!address || !isClient) return null

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <HamburgerMenuIcon className="h-6 w-6 cursor-pointer" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-24" sideOffset={10} align="end">
        <DropdownMenuGroup>
          <Link className="cursor-pointer" href={`/orders/${address}`}>
            <DropdownMenuItem>
              <ShoppingBag className="mr-2 h-4 w-4" />
              <span>Orders</span>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
