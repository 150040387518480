"use client"

import { ConnectBlock } from "@slice-so/react"
import Image from "next/image"
import logo from "public/baseLogo.png"
import { CustomConnect } from "./CustomConnect"
import Link from "next/link"
import { DropdownNav } from "./DropdownNav"
import { useAccount } from "wagmi"

export default function Navbar({
  enableSmartWallet = false,
  enableConnect = true
}) {
  const { address } = useAccount()

  return (
    <nav className="flex justify-between items-center">
      <Link href="/">
        <div className="flex items-center justify-center gap-2 sm:gap-3">
          <Image src={logo} alt="Base logo" className="w-10" />
          {/* <p className="text-[#E3E3E3] hidden sm:block font-light sm:text-lg">
            BASED MERCH
          </p> */}
        </div>
      </Link>
      <div className="flex gap-4 items-center">
        {/* <CustomConnect /> */}
        {enableConnect && (
          <ConnectBlock
            smartWalletEnabled={enableSmartWallet}
            buttonProps={{
              className:
                "!bg-[#E3E3E3] hover:!bg-[#E3E3E3]/80 !text-black !rounded-sm px-2"
            }}
          />
        )}
        {!!address && <DropdownNav />}
      </div>
    </nav>
  )
}
